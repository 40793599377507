import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { hot } from 'react-hot-loader';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import AppFrame from './AppFrame';
import { Switch, Route, Redirect } from 'react-router-dom';
import rooveeTheme from '../styles/rooveeTheme';
import { ThemeProvider } from '@material-ui/core';
import NotificationsHandler from './notifications/NotificationsHandler';
import './AppStyles.scss';
import routePaths from '../routePaths';
import SignInPage from './auth/signIn/SignInPage';
import HomePage from './HomePage';
import PlansPage from './plans/PlansPage';
import RidesPage from './rides/RidesPage';
import RideDetailsPage from './rides/RideDetailsPage';
import UserProfilePage from './userProfile/UserProfilePage';
import WalletHistoryPage from './walletHistory/WalletHistoryPage';
import RequestPhoneNumberChangePage from './auth/phoneNumberChange/RequestPhoneNumberChangePage';
import SetNewPhoneNumberPage from './auth/phoneNumberChange/SetNewPhoneNumberPage';
import OperatorTermsAndConditionsPage from './operators/OperatorTermsAndConditionsPage';
import OperatorsPage from './operators/OperatorsPage';
import PricingPage from './operators/PricingPage';
import CardsPage from './cards/CardsPage';
import PaymentRedirect from './PaymentRedirect';
import CampaignUnsubscribePage from "./campaignUnsubscribe";
import VerifyEmailPage from "./auth/verifyEmail";
import WithdrawalFundsPage from "./withdrawalFunds/WithdrawalFundsPage";
import Oauth2Page from "./auth/oauth2/oauth2Success";
import Oauth2FailurePage from "./auth/oauth2/oauth2Failure";
import ConfirmNewPhoneNumberPage from "./auth/confirmNewPhoneNumber/ConfirmNewPhoneNumberPage";
import ConfirmEmailPage from "./auth/confirmEmail";

if (!String.prototype.startsWith) {
  String.prototype.startsWith = function (searchString, position) {
    position = position || 0;
    return this.indexOf(searchString, position) === position;
  };
}

class App extends React.Component {
  constructor() {
    super();
  }

  renderUnauthorizedContent() {
    return (
      <div className="root">
        <Switch>
          <Route exact path={routePaths.paymentRedirect} component={PaymentRedirect} />
          <Route path={routePaths.verifyEmail} component={VerifyEmailPage} />
          <Route path={routePaths.confirmEmail} component={ConfirmEmailPage} />
          <Route path={routePaths.oauth2Failure} component={Oauth2FailurePage} />
          <Route path={routePaths.oauth2Success} component={Oauth2Page} />
          <Route path={`${routePaths.campaignUnsubscribe}/:userId`} component={withRouter(CampaignUnsubscribePage)} />
          <Route path={routePaths.signIn} component={SignInPage} />
          <Route path={routePaths.requestPhoneNumberChange} component={RequestPhoneNumberChangePage} />
          <Route path={routePaths.setNewPhoneNumber} component={SetNewPhoneNumberPage} />
          <Route path={routePaths.confirmNewPhoneNumber} component={ConfirmNewPhoneNumberPage} />
          <Redirect to={routePaths.signIn} push />
        </Switch>
        <NotificationsHandler />
      </div>
    );
  }

  getAppRoute() {
   return(
     <Switch>
       <Route exact path={routePaths.paymentRedirect} component={PaymentRedirect} />
       <Route exact path={routePaths.home} component={HomePage} />
       <Route path={routePaths.verifyEmail} component={VerifyEmailPage} />
       <Route path={routePaths.confirmEmail} component={ConfirmEmailPage} />
       <Route path={routePaths.oauth2Failure} component={Oauth2FailurePage} />
       <Route path={routePaths.oauth2Success} component={Oauth2Page} />
       <Route path={routePaths.plans} component={PlansPage} />
       <Route path={`${routePaths.campaignUnsubscribe}/:userId`} component={withRouter(CampaignUnsubscribePage)} />
       <Route path={routePaths.userProfile} component={UserProfilePage} />
       <Route path={routePaths.walletHistory} component={WalletHistoryPage} />
       <Route path={routePaths.withdrawalFunds} component={WithdrawalFundsPage} />
       <Route path={`${routePaths.rides}/:rideId`} component={RideDetailsPage} />
       <Route path={routePaths.rides} component={withRouter(RidesPage)} />
       <Route path={routePaths.operatorTerms} component={withRouter(OperatorTermsAndConditionsPage)} />
       <Route path={routePaths.operatorPricing} component={withRouter(PricingPage)} />
       <Route path={routePaths.operators} component={OperatorsPage} />
       <Route path={routePaths.cards} component={CardsPage} />
       <Route path={routePaths.confirmNewPhoneNumber} component={ConfirmNewPhoneNumberPage} />
       <Redirect to={routePaths.home} push />
     </Switch>
   )
  }

  renderAppContent() {
    return (
      <AppFrame userProfile={this.props.userState.userProfile || {}}>
        {this.getAppRoute()}
        <NotificationsHandler />
      </AppFrame>
    );
  }

  render() {
    return (
      <ThemeProvider theme={rooveeTheme}>
        <Suspense fallback="...">
            {this.props.auth.signedIn ? this.renderAppContent() : this.renderUnauthorizedContent()}
          </Suspense>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  userState: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
    userState: state.userState,
  };
}

export default hot(module)(connect(mapStateToProps)(App));
