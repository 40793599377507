import { create } from 'apisauce';
import config from '../config';

const api = create({
  baseURL: config.ROOVEE_API_URL,
  headers: {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json'
  },
  timeout: 10000
});

class AuthenticationApi {
  static requestSmsCode(signInData, preferredLanguage) {
    return api.post('/mobile/registration/phone', { ...signInData, preferredLanguage });
  }

  static requestEmailCode(signInData, preferredLanguage) {
    return api.post('/mobile/registration/email', { ...signInData, preferredLanguage });
  }

  static requestOauth2Url(provider) {
    return api.get(`/mobile/oauth2/${provider}/login`);
  }

  static requestOauth2Login(provider, body) {
    return api.post(`/mobile/oauth2/${provider}/login`, body);
  }

  static verifySmsCode(code, token) {
    return api.post('/mobile/registration/phone/verify', { code, token });
  }

  static verifyEmailCode(code, token) {
    return api.post('/mobile/registration/email/verify', { code, token });
  }

  static finishRegistration(firstName, lastName, email, isAdult, token) {
    return api.post('/mobile/registration/finish', { firstName, lastName, email, isJuvenile: !isAdult, token });
  }

  static finishRegistrationByOAuth2(token, email) {
    return api.post('/mobile/registration/finish/oauth2', { token, email });
  }

  static requestPhoneNumberChange(phoneNumberPrefix, phoneNumber, email) {
    return api.post('/mobile/account/phoneNumber/requestChange', { phoneNumberPrefix, phoneNumber, email });
  }

  static startNewPhoneNumberVerification(phoneNumberPrefix, phoneNumber, token) {
    return api.post('/mobile/account/phoneNumber/startChange', { phoneNumberPrefix, phoneNumber, token });
  }

  static completeNewPhoneNumberVerification(verificationCode, token) {
    return api.post('/mobile/account/phoneNumber/confirmChange', { verificationCode, token });
  }

  static confirmNewPhoneNumber(token) {
    return api.post('/mobile/account/confirmNewPhoneNumber', { token });
  }

  static verifyEmail(token) {
    return api.post('/mobile/account/verifyEmail', { token });
  }

  static confirmEmail(token) {
    return api.post('/mobile/account/confirmEmail', { token });
  }
}

export default AuthenticationApi;
